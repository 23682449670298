//IMPORTS
import React, { useEffect, useState } from 'react';
import Problem from '~icons/carbon/scales-tipped';
import Template from '~icons/carbon/group-presentation';
import Article from '~icons/carbon/blog';

/** Component displaying a live counter of how many problem cards, template, and articles there are */
export default function Stats({
	problems = 0,
	templates = 0,
	articles = 0,
}: {
	problems: number;
	templates: number;
	articles: number;
}) {
	return (
		<div className='flex flex-col items-center gap-16 px-16 text-xl sm:flex-row sm:items-start sm:justify-between'>
			{/* PROBLEMS */}
			<div className='flex w-52 flex-col rounded sm:w-1/3'>
				<Problem className='mb-6 mr-2 inline align-[-.16em] text-3xl opacity-70' />
				<p className='text-3xl font-bold'>
					<CountUpAnimation duration={Math.cbrt(problems) * 250}>{problems}</CountUpAnimation>
				</p>
				<p>Ethical Dilemmas</p>
			</div>
			{/* TEMPLATES */}
			<div className='flex w-52 flex-col rounded sm:w-1/3'>
				<Template className='mb-6 mr-2 inline align-[-.16em] text-3xl opacity-70' />
				<p className='text-3xl font-bold'>
					<CountUpAnimation duration={Math.cbrt(templates) * 250}>{templates}</CountUpAnimation>
				</p>
				<p>Workshops Premade</p>
			</div>
			{/* ARTICLES */}
			<div className='flex w-52 flex-col rounded sm:w-1/3'>
				<Article className='mb-6 mr-2 inline align-[-.16em] text-3xl opacity-70' />
				<p className='text-3xl font-bold'>
					<CountUpAnimation duration={Math.cbrt(articles) * 250}>{articles}</CountUpAnimation>
				</p>
				<p>Articles Available</p>
			</div>
		</div>
	);
}

//ANIMATION PROPERTIES for counter
const easeOutQuad = t => t * (2 - t);
const frameDuration = 1000 / 60;

/**
 * Animation counting up from 0 to desired number
 *
 * @param duration - Measured in milliseconds, defaults to two seconds
 */
const CountUpAnimation = ({ children, duration = 2000 }) => {
	//USE STATE
	const [count, setCount] = useState(0);
	//desired number
	const countTo = parseInt(children, 10);

	//determining SPEED using frames
	useEffect(() => {
		let frame = 0;
		const totalFrames = Math.round(duration / frameDuration);
		const counter = setInterval(() => {
			frame++;
			const progress = easeOutQuad(frame / totalFrames);
			setCount(countTo * progress);

			if (frame === totalFrames) {
				clearInterval(counter);
			}
		}, frameDuration);
	}, []);

	return Math.floor(count);
};
